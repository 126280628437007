<template>
  <div class="py-50">
    <b-tabs id="menu" class="mb-2">
      <b-tab title="Models">
        <div v-if="taskId && task.info">
          <h5 class="ml-1">
            Importing Model
          </h5>
          <hr>
          <div v-if="!importedModel.length">
            <b-card style="height: 10vh">
              <div class="text-primary font-weight-bold mb-1 mr-1">
                {{ task.info.msg }}
              </div>
              <b-progress v-if="task.info.total" :value="task.info.created" :max="task.info.total" show-value />
              <looping-rhombuses-spinner v-else style="min-height: 5vh" />
            </b-card>
          </div>
          <temp-model-table v-else :table-data="importedModel" />
        </div>

        <h5 class="ml-1 mt-2">
          My Models
        </h5>
        <hr>
        <model-table v-if="userModels.length > 0" :table-data="userModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Models
        </h5>
        <hr>
        <model-table v-if="sharedModels.length > 0" :table-data="sharedModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Models
        </h5>
        <hr>
        <model-table v-if="publicModels.length > 0" :table-data="publicModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public models found.</span>
        </div>
      </b-tab>

      <!-- Model Snapshots Tab -->
      <b-tab title="Snapshots">
        <h5 class="ml-1 mt-2">
          My Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="tempUserModels.length > 0" id="model-snapshots-table" :table-data="tempUserModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="tempSharedModels.length > 0" :table-data="tempSharedModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="tempPublicModels.length > 0" :table-data="tempPublicModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public snapshots found.</span>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import ModelTable from '@/views/Home/ModelTable.vue'
import TempModelTable from '@/views/Home/TempModelTable.vue'
import coreService from '@/libs/api-services/core-service'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoopingRhombusesSpinner from '@/components/Spinners/LoopingRhombusesSpinner.vue'

export default {
  name: 'ModelsBrowser',
  components: {
    ModelTable,
    TempModelTable,
    LoopingRhombusesSpinner,
  },
  computed: {
    taskId() {
      this.getTaskStatus(this.$store.state.model.import_task_id)
      return this.$store.state.model.import_task_id
    },
  },
  setup(props, context) {
    const userModels = ref([])
    const getUserModels = () => {
      axiosIns
        .get('/api/v2/models/user')
        .then(({ data }) => { userModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getUserModels] ${e}`))
    }
    getUserModels()

    const sharedModels = ref([])
    const getSharedModels = () => {
      axiosIns
        .get('/api/v2/models/shared')
        .then(({ data }) => { sharedModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getSharedModels] ${e}`))
    }
    getSharedModels()

    const publicModels = ref([])
    const getPublicModels = () => {
      axiosIns
        .get('/api/v2/models/public')
        .then(({ data }) => { publicModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getPublicModels] ${e}`))
    }
    getPublicModels()

    const allTempModels = ref([])
    const tempPublicModels = ref([])
    const tempSharedModels = ref([])
    const tempUserModels = ref([])

    const getTempModels = () => {
      coreService
        .get('/v1/model/temp')
        .then(({ data }) => {
          allTempModels.value = data.all_models
          tempPublicModels.value = data.public_models
          tempSharedModels.value = data.shared_models
          tempUserModels.value = data.user_models
        })
        .catch(e => console.error(e))
    }
    getTempModels()

    const getAllModels = () => {
      getTempModels()
      getPublicModels()
      getSharedModels()
      getUserModels()
    }

    const task = ref({})
    const importedModel = ref([])

    const getTaskStatus = taskId => {
      if (!taskId) {
        task.value = {}
        return
      }
      coreService.get(`/v1/async_tasker/tasks/${taskId}`)
        .then(({ data }) => {
          task.value = data
          console.log(data.status)
          if (['PROGRESS', 'PENDING'].includes(data.status)) {
            setTimeout(() => getTaskStatus(taskId), 500)
          } else if (data.status === 'SUCCESS') {
            const importedModelId = data.info.model_id
            coreService.get('/v1/model/temp')
              .then(({ data }) => {
                importedModel.value = [
                  (data.user_models.concat(data.public_models)).find(model => model.id === importedModelId),
                ]
                store.state.model.import_task_id = null
              })
          } else {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Model Snapshot Import Failed',
                icon: 'XIcon',
                variant: 'danger',
                text: data.result,
              },
            })
          }
        })
        .catch(e => console.error(e))
    }

    return {
      userModels,
      sharedModels,
      publicModels,
      allTempModels,
      tempPublicModels,
      tempSharedModels,
      tempUserModels,
      task,
      importedModel,
      getTempModels,
      getAllModels,
      getTaskStatus,
    }
  },
}
</script>
